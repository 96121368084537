/**
 * These variables are replaced by the contents of environment variables in setEnvVarsOnContainerStartup.sh when the container starts.
 * When changing the defaults, also change these in setEnvVarsOnContainerStartup.sh.
 */
export default {
  version: 'DevelopmentVersion',
  backendUrl: 'https://localhost:8080',
  backendWs: 'wss://localhost:8080',
  reloadAgentStatus: '15000',
  supportUrl: 'https://community.boomi.com/s/support',
  salesUrlMeeting: 'https://info.apiida.com/meetings/peter-moersch',
  agentImage: 'apiida/controlplane-agent',
  insertTenantId: 'addTenantIdToBackendUrl',
  tinyEditorApiKey: 'udx2ebvck93caajup3lm2uilgj65r8klsu7ew7swvsw4u4zz',
};
